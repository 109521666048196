<template>
  <div
    :tabindex="!isDisabled ? 0 : -1"
    class="input-toggle"
    @click="toggle"
    @keydown.enter="toggle"
    v-class-mod:input-toggle="{
      isToggled,
      isDisabled,
      isMixed,
      showTransitions,
    }"
  >
    <div class="input-toggle__row">
      <template v-for="(option, key) in options" :key="key">
        <span v-if="option === 'seperator'" class="input-toggle__seperator" />
        <span v-else-if="option === 'toggle'" class="input-toggle__switch">
          <span v-if="color" class="input-toggle__custom-color" v-set-colors="{ background: color }" />
          <span v-if="isLoading || internalIsLoading" class="input-toggle__loader" />
        </span>
        <icon-wrapper v-else-if="'icon' in option" v-bind="option" class="input-toggle__icon-" />
        <span
          v-else-if="'placeholder' in option"
          v-bind="option"
          class="input-toggle__placeholder"
          v-class-mod:input-toggle__placeholder="option.size"
          v-set-colors="{ color: option.color }"
        >
          <cut-text :text="option.placeholder" />
        </span>
      </template>
    </div>
    <p v-if="explanation" class="input-toggle__explanation">{{ explanation }}</p>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, onMounted, ref } from "vue";

import { vSetColors } from "@horizon56/directives/set-colors";
import { ColorName } from "@horizon56/styles/types";
import { delayMs } from "@horizon56/utils";

import CutText from "@/components/content/cut-text.vue";
import IconWrapper, { IconWrapperProps } from "@/components/icons/icon-wrapper.vue";

type Toggle = "toggle";
type Seperator = "seperator";
type Icon = IconWrapperProps;
type Placeholder = { placeholder: string; color?: ColorName; size?: "base" | "label" };
type Option = Toggle | Seperator | Icon | Placeholder;

const props = defineProps<{
  isToggled: boolean;
  isMixed?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  options: Option[];
  color?: ColorName;
  explanation?: string;
  update?: (is: boolean) => Promise<any>;
}>();

const internalIsLoading = ref(false);

const emits = defineEmits<{ (e: "update", a: boolean): void }>();

const toggle = async () => {
  if (!props.isDisabled) {
    emits("update", !props.isToggled);
    if (typeof props.update === "function") {
      internalIsLoading.value = true;
      await props.update(!props.isToggled);
      internalIsLoading.value = false;
    }
  }
};

const showTransitions = ref(false);
onMounted(() =>
  nextTick(async () => {
    await delayMs(300);
    showTransitions.value = true;
  }),
);
</script>

<style lang="scss" scoped>
@import "@horizon56/styles";
.input-toggle {
  $block: &;
  display: flex;
  flex-flow: column;
  user-select: none;
  justify-content: center;
  outline: none;
  gap: var(--app-spacing-size-small);
  --size: 8px;
  --left: 4px;
  --toggle-color: var(--green-500);
  --background: var(--black-20);
  &__row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: var(--app-spacing-size-small);
  }
  &__seperator {
    display: inline-flex;
    flex-grow: 1;
  }
  &__switch {
    height: 16px;
    width: 35px;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    background: var(--background, var(--toggle-color));
    border-radius: var(--app-radius-large);
    &:after {
      content: "";
      position: absolute;
      left: var(--left);
      top: calc((16px - var(--size)) / 2);
      height: var(--size);
      width: var(--size-width, var(--size));
      border-radius: var(--size);
      background: var(--static-white);
    }
  }
  &__custom-color {
    opacity: 0;
    position: absolute;
    @include position-corner;
  }
  &__placeholder {
    cursor: pointer;
    font-size: var(--app-font-size-base);
    color: var(--black-90);
    &--label {
      font-weight: 500;
      font-size: var(--app-font-size-label);
    }
  }
  &__loader {
    position: absolute;
    @include position-corner(0);
    border-radius: var(--app-radius-large);
    overflow: hidden;
    @include loading-bars(
      $state: paused,
      $loader-height: 2px,
      $fixed: true,
      $has-position: true,
      $color: var(--static-white)
    );
    @include loading-bars(true);
  }
  &__explanation {
    width: 100%;
    line-height: 1.28;
    font-size: var(--app-font-size-label);
    color: var(--black-50);
  }
  &:has(&__placeholder) {
    min-height: var(--app-input-label-height);
  }
  &:not(#{&}--isDisabled) {
    cursor: pointer;
  }
  &:not(#{&}--isDisabled):not(#{&}--isMixed):has(#{&}__loader),
  &:not(#{&}--isDisabled):not(#{&}--isMixed):active {
    --size-width: 16px;
  }
  &:not(#{&}--isMixed)#{&}--isToggled {
    --size: 12px;
    --left: calc(100% - 14px);
    --background: var(--toggle-color);
    &:has(#{$block}__loader),
    &:active {
      --size-width: 18px;
      --left: calc(100% - 20px);
    }
    #{$block}__custom-color {
      opacity: 1;
    }
  }
  &:where(#{&}--isMixed) {
    --background: var(--black-20);
    --size: 8px;
    --size-width: 27px;
    --left: 4px;
  }
  &--isDisabled {
    pointer-events: none;
    opacity: 0.7;
  }
  &--showTransitions #{&} {
    &__switch:after {
      transition:
        left var(--app-transition),
        top var(--app-transition),
        width var(--app-transition),
        height var(--app-transition);
    }
  }
  &:focus-visible #{&}__switch {
    @include focus-outline-inner;
  }
}
</style>
